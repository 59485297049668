import { useEffect, useState } from "react";
import ReactPaginate from "react-paginate"; //  Using react-paginate from the react library
import styled from "styled-components";
import FilterBy from "./FilterBy";
import { HOST_URL, reqOptions } from "../assets/js/helpFunction";
import { getDate, getEstateID } from "./partials/utils";

const RecentActivity = ({logs}) => {
  const [data, setData] = useState({});
  const [search, setSearch] = useState("");
  const [selected, setSelected] = useState("Filter");

  // useEffect(() => {
  //   let requestOptions = reqOptions("get", null, true);
  //   const api = `${HOST_URL()}estate_access_logs/?estate_id=${getEstateID()}`;
  //   fetch(api, requestOptions)
  //     .then((res) => res.json())
  //     .then(data => {
  //       setData(data)
  //       setLogs(data.results);
  //     })
  //     .catch((err) => console.log(err));
  // }, [])

  return (
    <Container>
      <section className="dinko">
        <HandleSearchAndTab>
          <h4>Access Log</h4>
        </HandleSearchAndTab>
      </section>
      <TableFrame>
          <div className="scrollable_table">
            <div className="activity_table">
              <table>
                <thead>
                  <tr>
                    <th>Host's Name</th>
                    <th>Guest's Name</th>
                    <th>Access Code</th>
                    <th>Destination</th>
                    <th>Date and Time</th>
                    <th>Status</th>
                  </tr>
                </thead>
                <tbody>
                  {logs && logs.length
                    ? logs.map((log) => (
                        <tr key={log.id}>
                          <td>
                            {log.estate_user.user.first_name} {log.estate_user.user.last_name}
                          </td>
                          <td>
                            {log.first_name} {log.last_name}
                          </td>
                          <td>{log.access_code}</td>
                          <td>{log.address}</td>
                          <td className="defaultforDate">
                            {getDate(log.from_date)}
                          </td>
                          <td className="resume_data">
                            <p
                              className={
                                log.access === "GRANT" ? "entry" : "exit"
                              }
                            >
                              {log.access === "GRANT" ? "Granted" : "Declined"}
                            </p>
                          </td>
                        </tr>
                      ))
                    : ""}
                </tbody>
              </table>
            </div>
          </div>
        </TableFrame>
    </Container>
  );
};

export default RecentActivity;
const Container = styled.div`
  .dinko {
    margin-top: 45px;
  }
`;
const TableFrame = styled.div`
  .transaction-heading {
    font-family: "Satoshi";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 121%;

    /* Grey/1 */

    color: #111111;
  }
  .greenBorder {
    color: #2d4bf3;
    padding: 5px 15px;
    border-radius: 5px;
    text-align: center;
    font-weight: 600;
    border: 2px solid #2d4bf3;
  }
  .redBorder {
    color: #ff0000;
    padding: 5px 15px;
    border-radius: 5px;
    text-align: center;
    font-weight: 600;
    border: 2px solid #ff0000;
  }
  .data-zone {
    display: flex;
    align-items: center;
  }
  .zone-name {
    margin-left: 10px;
  }
  .transaction-container {
    margin-top: 40px;
  }

  .transaction-date {
    font-weight: bold;
  }

  .transaction-row {
    width: 100px;
    height: 18px;
    font-family: "DM Sans";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 18px;
    color: #545454;
  }
  .cntr {
    margin-left: 15px;
  }
`;

const HandleSearchAndTab = styled.section`
  .container {
    margin-bottom: 20px;
  }
  .normal_tab {
    background: transparent;
  }
  button {
    padding: 10px 12px !important;
    height: auto !important;
    font-weight: 400;
    border: 1px solid #2d4bf3 !important;
  }
  .event-input {
    max-width: 675px;
  }
  @media (max-width: 420px) {
    .search_set,
    button {
      width: 100%;
      margin-bottom: 20px;
    }
  }
  @media (min-width: 760px) {
    display: flex;
    justify-content: space-between;
    .search_set {
      width: 220px;
    }
    .outlined {
      width: 180px !important;
    }
    .event-input {
      display: flex;
      justify-content: space-between;
      //   width: 57%;
      .select_me {
        margin: 0 10px;
        width: 135px;
      }
      button {
        margin-top: 0;
        height: 45px;
      }
      .event-select {
        width: 20%;
      }
    }
  }
  @media (max-width: 540px) {
    .event-input {
      .event-select {
        display: none;
      }
    }
  }
  .tabs {
    margin: 0;
    margin-bottom: 30px;
    .tab {
      margin: 0;
      height: 45px;
      align-items: center;
      display: flex;
      justify-content: center;
      padding: 0;
      min-width: 100px;
      padding: 5px 20px;
      text-align: center;
      margin-right: 15px;
      border-bottom: 3px solid #c0c0c0;
      button {
        margin: 0;
        padding: 0 !important;
        padding: 10px;
        //styleName: Web/Small Copy;

        font-size: 16px;
        font-weight: 400;
        line-height: 17px;
        letter-spacing: 0em;
        text-align: left;
      }
    }
    .active {
      background: transparent;
      border-bottom: 3px solid #2d4bf3;
      button {
        color: #2d4bf3;
      }
    }
  }
  .normal_tab.add_tape {
    border-bottom: 2px solid yellow;
  }
`;
