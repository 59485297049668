import { useEffect, useState } from "react";
import styled from "styled-components";
import { HOST_URL, reqOptions } from "../../assets/js/helpFunction";
import { MssgContainer, getDate, getEstateID } from "../../components/partials/utils";
import { WithResidentAuth } from "./Dashboard";
import { SVGs } from "../../assets/svg/SVGs";

function ResidentActivityLog() {
  const [data, setData] = useState({});
  const [logs, setLogs] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [filter, setFilter] = useState({
    name: "",
    mode: ""
  })


  const [mssg, setMssg] = useState({ error: false, mssg: "" })

  const closeMssg = () => {
    setMssg({ error: false, mssg: "" })
  }

  const filterLog = () => {
    let search_string = ``
    let requestOptions = reqOptions("get", null, true);
    if (filter.name) {
      search_string += "&search=" + filter.name
    }

    if (filter.mode) {
      search_string += "&status=" + filter.mode
    }

    const api = `${HOST_URL()}estate_access_logs/?estate_id=${getEstateID()}${search_string}`;
    fetch(api, requestOptions)
      .then((res) => res.json())
      .then(data => {
        setLogs(data.results);
      })
      .catch((err) => console.log(err));
  }

  const shareCode = (access) => {
    navigator.share({
      title: 'Access Code',
      text: `
Hi ${access.first_name} ${access.last_name},

Your ${access.access_type.toLowerCase().replace('_', " -")} code is 

${access.access_code}

Address : ${access.address},

from ${getDate(access.from_date)} to ${getDate(access.to_date)}

Powered by: www.estateiq.ng
      `,
    })
      .then(() => { })
      .catch((error) => console.error('Error sharing:', error));
  }


  useEffect(() => {
    let requestOptions = reqOptions("get", null, true);
    const api = `${HOST_URL()}estate_access_logs/?estate_id=${getEstateID()}`;
    fetch(api, requestOptions)
      .then((res) => res.json())
      .then(data => {
        setData(data)
        console.log(data)
        setLogs(data.results);
      })
      .catch((err) => setMssg({ ...mssg, mssg: err.message, error: true }));
  }, []);

  const CreateAccess = resident => {
    const requestOptions = reqOptions("post", JSON.stringify(resident), true);
    //
    const api = `${HOST_URL()}estate_access_logs/?estate_id=${getEstateID()}`;
    fetch(api, requestOptions)
      .then((res) => res.json())
      .then((data) => {
        console.log(data, 'datatat')
        if (data.access) {
          setMssg({ ...mssg, mssg: "Access code created successfully" })
          setLogs([...logs, data])
          setOpenModal(false)
          shareCode(data)
        } else {
          setMssg({ ...mssg, mssg: data.message, error: true })
        }
      })
      .catch((err) => setMssg({ ...mssg, mssg: err.message, error: true }));
  }

  const getAccessModifier = (access) => {
    return access === "GRANT" ? "REVOKE" : "GRANT"
  }

  const modifyAccess = (access, access_code) => {
    const requestOptions = reqOptions("post", JSON.stringify({ access, access_code }), true);
    const api = `${HOST_URL()}estate_access_logs/modify/?estate_id=${getEstateID()}`;
    fetch(api, requestOptions)
      .then((res) => res.json())
      .then(data => {
        if (data && data.message) {
          setMssg({ ...mssg, mssg: "Access Modified successfully" })
          const edited_data = logs.map(item => {
            if (item.access_code == access_code) {
              return { ...item, access }
            }
            return item
          })
          setLogs(edited_data)
        } else {
          setMssg({ ...mssg, mssg: "Error Modifying Access Log", error: true })
        }
      })
      .catch((err) => setMssg({ ...mssg, mssg: err.message, error: true }));
  }

  const handleCloseModal = () => {
    setOpenModal(false)
  }


  const Mode = ({ selected, setSelected }) => {
    const [isActive, setIsActive] = useState(false);
    const options = ["Entry", "Exit"];
    return (
      <div className="select_me filter_drop">
        <div className="select-btn" onClick={(e) => setIsActive(!isActive)}>
          {selected}
          <svg
            width="25"
            height="24"
            viewBox="0 0 25 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M6.5 9L12.5 15L18.5 9"
              stroke="#545454"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        </div>
        {isActive && (
          <div className="select_content">
            <div
              className="select_items"
              onClick={(e) => {
                setSelected("Entry");
                setIsActive(false);
              }}
            >
              <p>Entry</p>
            </div>
            <div
              className="select_items"
              onClick={(e) => {
                setSelected("Exit");
                setIsActive(false);
              }}
            >
              <p>Exit</p>
            </div>
          </div>
        )}
      </div>
    );
  };
  return (
    <WithResidentAuth>
      <div className="event-container">
        {mssg.mssg && <MssgContainer mssg={mssg} close={closeMssg} />}
        <HandleSearchAndTab>
          <button
            className="important-btn"
            onClick={() => setOpenModal(true)}
          >
            Generate Access Code
          </button>
          <div className="event-input">
            <div class="search_set">
              <input
                className="eventt"
                type="text"
                name="name"
                value={filter.name}
                placeholder="Search Name"
                onChange={(e) => setFilter({ ...filter, name: e.target.value })}
              />
            </div>
            <div className="select_me filter_drop">
              <select value={filter.mode} name="mode" id="" onChange={e => setFilter({ ...filter, mode: e.target.value })} className="form-control">
                <option value="">Filter</option>
                <option value="EXIT">Exit</option>
                <option value="ENTRY">Entry</option>
              </select>
            </div>
            <div>
              <button className="btn btn-primary" style={{ background: "#2d4bf3" }} onClick={filterLog}>
                <img src={SVGs.filter} alt="filter" style={{ maxWidth: "20px" }} />
              </button>
            </div>
            {/* <Mode selected={entry} setSelected={setEntry} /> */}
          </div>
        </HandleSearchAndTab>
      </div>
      <TableFrame>
        <div className="scrollable_table">
          <div className="activity_table">
            <table>
              <thead>
                <tr>
                  <th>Granted by</th>
                  <th>Granted to</th>
                  <th>Access type</th>
                  <th>Entry date & time</th>
                  <th>Exit date & time</th>
                  <th>Status</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {logs && logs.length ? logs.map(log => (
                  <tr key={log.id}>
                    <td>
                      <div className="img-avatar">
                        <div className="user-name">{log.estate_user.user.first_name} {log.estate_user.user.last_name}</div>
                      </div>
                    </td>
                    <td>
                      {log.first_name} {log.last_name}
                    </td>
                    <td className="Residents-address">{log.access_type.replace('_', "-")}</td>
                    <td>{log.from_date ? getDate(log.from_date) : 'Not applicable'}</td>
                    <td>
                      <div className="date-time">{log.from_date ? getDate(log.to_date) : 'Not applicable'}</div>
                    </td>
                    <td className="resume_data">
                      <p className={log.status.toLowerCase()}>{log.status}</p>
                    </td>
                    <td>
                      <div className="img-avatar">
                        <div className="user-name">
                          <button className="important-btn"
                            onClick={() => modifyAccess(getAccessModifier(log.access), log.access_code)}
                            style={{ backgroundColor: getAccessModifier(log.access) === "REVOKE" ? 'red' : 'green' }}
                          >
                            {getAccessModifier(log.access)}
                          </button>
                        </div>
                      </div>
                    </td>
                  </tr>
                )
                ) : ""}
              </tbody>
            </table>
          </div>
        </div>
      </TableFrame>
      <AccessPin open={openModal} onClose={handleCloseModal} createAccess={CreateAccess} />
    </WithResidentAuth>
  );
}

export const AccessPin = ({ open, onClose, createAccess, }) => {
  // const data = edit_data
  const default_data = {
    first_name: "",
    last_name: "",
    access_type: "",
    guest_type: "",
    from_date: "",
    to_date: "",

  }
  const [resident, setResident] = useState(default_data)
  useEffect(() => {
    setResident(default_data)
  }, [])

  if (!open) return null;
  const handleChange = e => {
    const { name, value } = e.target
    setResident({ ...resident, [name]: value })

  }

  const handleSubmit = e => {
    e.preventDefault()

    createAccess(resident)
    setResident(default_data)
    // onClose(false)
  }
  return (
    <div onClick={onClose} className="bills_on_me">
      <div
        onClick={(e) => {
          e.stopPropagation();
        }}
        className="modalContainer"
      >
        <form className="new_estates_form fade-in-bck" onSubmit={handleSubmit}>
          <div className="form-group">
            <div className="create_est ">
              <div className="form_txt">
                <h3>Access Code</h3>
              </div>
              <input
                type="text"
                value={resident.first_name}
                name="first_name"
                placeholder="First Name (Guest)"
                onChange={handleChange}
                required
              />

              <input
                type="text"
                value={resident.last_name}
                name="last_name"
                placeholder="Last Name (Guest)"
                onChange={handleChange}
                required
              />
              <label htmlFor="">Access Type</label>
              <select name="access_type" id="" value={resident.access_type} className="form-control"
                style={{ color: 'black', margin: "5px 0" }} onChange={handleChange} required>
                <option value="">Select</option>
                <option value="ONE_TIME">One Time</option>
                <option value="PERMANENT">Permanent</option>
              </select>

              <label htmlFor="">Guest Type</label>
              <select name="guest_type" id="" value={resident.guest_type} className="form-control"
                style={{ color: 'black', margin: "5px 0" }} onChange={handleChange} required>
                <option value="">Select</option>
                <option value="VISITOR">Visitor</option>
                <option value="ARTISAN">Artisan</option>
                <option value="TAXI">Taxi</option>
                <option value="DELIVERY">Delivery</option>
                <option value="EVENT">Event</option>
                <option value="OTHERS">Others</option>
              </select>
              <div>
                <label>Arrival Date</label>
                <input
                  type="datetime-local"
                  value={resident.from_date}
                  name="from_date"
                  placeholder="From date"
                  onChange={handleChange}
                  required
                  min={new Date().toISOString().slice(0, 16)}
                />
              </div>
              <div>
                <label>Departure Date</label>
                <input
                  type="datetime-local"
                  value={resident.to_date}
                  name="to_date"
                  placeholder="To date"
                  onChange={handleChange}
                  required
                  min={new Date().toISOString().slice(0, 16)}
                />
              </div>


            </div>
          </div>
          <button className="btn btn-success btn-block">Generate Code</button>
        </form>
        <img src={SVGs.close} alt="" onClick={onClose} />
      </div>
    </div>
  );
};

const HandleSearchAndTab = styled.section`
  align-items: center;
  .container {
    margin-bottom: 10px;
  }
  .normal_tab {
    background: transparent;
  }
  h3 {
    margin: 0;

    font-size: 30px;
    font-weight: 500;
    line-height: 36px;
    letter-spacing: 0em;
    text-align: left;
  }
  .event-input {
    max-width: 675px;
  }
  @media (max-width: 420px) {
    .search_set,
    button {
      width: 100%;
      margin-bottom: 20px;
    }
  }
  @media (min-width: 760px) {
    display: flex;
    justify-content: space-between;
    .search_set {
      width: 220px;
    }
    .outlined {
      width: 180px !important;
    }
    .event-input {
      display: flex;
      justify-content: space-between;
      //   width: 57%;
      .select_me {
        margin: 0 10px;
        width: 135px;
      }
      button {
        margin-top: 0;
        height: 45px;
      }
      .event-select {
        width: 20%;
      }
    }
  }
  @media (max-width: 540px) {
    .event-input {
      .event-select {
        display: none;
      }
    }
  }
  .tabs {
    margin: 0;
    margin-bottom: 30px;
    .tab {
      margin: 0;
      height: 45px;
      align-items: center;
      display: flex;
      justify-content: center;
      padding: 0;
      min-width: 100px;
      padding: 5px 20px;
      text-align: center;
      margin-right: 15px;
      border-bottom: 3px solid #c0c0c0;
      button {
        margin: 0;
        padding: 0 !important;
        padding: 10px;
        //styleName: Web/Small Copy;

        font-size: 16px;
        font-weight: 400;
        line-height: 17px;
        letter-spacing: 0em;
        text-align: left;
      }
    }
    .active {
      background: transparent;
      border-bottom: 3px solid #2d4bf3;
      button {
        color: #2d4bf3;
      }
    }
  }
  .normal_tab.add_tape {
    border-bottom: 2px solid yellow;
  }
`;

const FilterBy = ({ selected, setSelected }) => {
  const [isActive, setIsActive] = useState(false);
  const options = ["Last 7days", "Last 14 days", "This month", "This year"];
  return (
    <div className="select_me filter_drop">
      <div className="select-btn" onClick={(e) => setIsActive(!isActive)}>
        {selected}
        <svg
          width="25"
          height="24"
          viewBox="0 0 25 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M6.5 9L12.5 15L18.5 9"
            stroke="#545454"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      </div>
      {isActive && (
        <div className="select_content">
          {/* <h5>Duration</h5> */}
          {options.map((option) => (
            <div
              className="select_items"
              onClick={(e) => {
                setSelected(option);
                setIsActive(false);
              }}
            >
              {option}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

const TableFrame = styled.div`
  @media (max-width: 900px) {
    .scroll {
      min-width: 950px;
      width: 100%;
    }
  }
  .activity_table {
    /* Grey/2 */
    color: #545454;
  }
  .user-name {
    margin-left: 10px;
  }
  .team-members {
    margin-top: 40px;
    width: 65%;
  }
  // .date-time {
  //     width: 81px;
  //     height: 36px;
  //     font-family: 'DM Sans';
  //     font-style: normal;
  //     font-weight: 400;
  //     font-size: 16px;
  //     line-height: 18px;
  //     display: flex;
  //     align-items: center;
  //     color: #545454;
  // }
  .Residents-address {
    width: 190px;
    height: 34px;
    font-family: "Satoshi";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
  }

  .resume_data {
    margin: 15px;
    width: 32px;
    height: 18px;
    font-family: "DM Sans";
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 18px;
    display: flex;
    // justify-content: space-between;
    align-items: center;
    letter-spacing: -0.06em;
  }

  .img-entry {
    margin: 5px;
  }
  .activity-time {
    font-weight: 700;
  }
`;

export default ResidentActivityLog;
// DATA

