import { useEffect, useState } from "react";
import styled from "styled-components";
import Dial from "./DialNumber";
import GiveAccess from "./ShareAccess";
import { MssgContainer, getDate, getEstateID } from "./partials/utils";
import { AccessPin } from "../Pages/Residents/ActivityLog";
import { HOST_URL, reqOptions } from "../assets/js/helpFunction";
import { Link } from "react-router-dom";

const D_Filter = ({datas}) => {
  const [emergency, setEmergency] = useState(false);
  const [openModal, setOpenModal] = useState(false);

  const [mssg, setMssg] = useState({error : false, mssg : ""})
  const estate_id = localStorage.getItem("estate_id")

  const closeMssg = () => {
    setMssg({error : false, mssg : ""})
  }

  const shareCode = (access) => {
    navigator.share({
      title: 'Access Code',
      text: `
Hi ${access.first_name} ${access.last_name},

Your ${access.access_type.toLowerCase().replace('_', " -")} code is 

${access.access_code}

Address : ${access.address},

from ${getDate(access.from_date)} to ${getDate(access.to_date)}

Powered by: www.estateiq.ng
      `,
    })
    .then(() => {})
    .catch((error) => console.error('Error sharing:', error));
  }



  const CreateAccess = resident => {
    const requestOptions = reqOptions("post", JSON.stringify(resident), true);
    //
    const api = `${HOST_URL()}estate_access_logs/?estate_id=${getEstateID()}`;
    fetch(api, requestOptions)
      .then((res) => res.json())
      .then((data) => {
        if(data.access){
          setMssg({...mssg, mssg : "Access code created successfully"})
          setOpenModal(false)
          shareCode(data)
        }else{
          setMssg({...mssg, mssg : data.message, error : true})
        }
      })
      .catch((err) => setMssg({...mssg, mssg : err.message, error : true}));
  }

  const handleCloseModal = () => {
    setOpenModal(false)
  }

  return (
    <OnboardingTag>
      <div className="download_filter">
      {mssg.mssg && <MssgContainer mssg={mssg} close={closeMssg}/>}
        <div className="ff-right">
          <h1>Welcome {datas.user && datas.user.estate_user.user ? datas.user.estate_user.user.first_name : ""}</h1>
          <p>#{estate_id}</p>
        </div>
        <div className="filter_download">
          <button className="outlined-btn gen-access" onClick={() => setOpenModal(true)}>
            Generate Access Code
          </button>
          <button className="outlined-btn report-issue" onClick={() => setOpenModal(true)}>
            <Link to={"/resident/complaint"}>
              Report an Issue
            </Link>
          </button>
          <button className="outlined-btn" onClick={() => setEmergency(true)}>
            Emergency Line
          </button>
        </div>
      </div>

      <Dial open={emergency} onClose={() => setEmergency(false)} />
      <AccessPin open={openModal} onClose={handleCloseModal} createAccess={CreateAccess}/>
      {/* <SetEmergency open={ViewSOS} onClose={() => setViewSOS(false)} /> */}
    </OnboardingTag>
  );
};

export default D_Filter;

let OnboardingTag = styled.div`
  .ff-right {
    margin-bottom: 30px;
  }

  .gen-access {
      color: #2d4bf3 !important;
      border-color: #2d4bf3 !important;
      &:hover {
        background-color: #2d4bf3 !important;
        color: #ffffff !important;
      }
  }

  .report-issue {
      color: #000000 !important;
      border-color: #000000 !important;
      &:hover {
        background-color: #000000 !important;
        color: #ffffff !important;
      }
    }

  @media screen and (max-width : 420px) {
    /* .filter_download{
      display: block !important;
    } */
    .outlined-btn{
      width: 100%;
      padding: 20px 0px !important;
      height: auto !important;
      margin: 20px 0;
    }

  }
  @media (max-width: 414px) {
    button {
      width: 48%;
    }
  }
  @media (min-width: 768px) {
    .download_filter {
      height: 60px;
    }
  }
  .filter_download {
    @media (min-width: 768px) {
      .outlined-btn {
        margin-right: 10px;
      }
    }
    .outlined-btn {
      margin-right: 10px;
      color: #ff0000;
      border-color: #ff0000;
      &:hover {
        background-color: #ff0000;
        color: #ffffff;
      }
    }
    button {
      margin-top: 0;
      padding: 10px 20px;
      height: 45px;
      // border: 2px solid #1737e6;
      font-weight: 500;
      // background: #1737e6;
      // color: #ffffff;
      transition: 0.4s;
      transform: scale(1);
      &:hover {
        transform: scale(0.95);
      }
    }
  }
  @media (min-width: 768px) {
    .outlined {
      margin-right: 10px;
    }
  }
`;
