import { useEffect, useState } from 'react'
import { HOST_URL, reqOptions } from "../../assets/js/helpFunction";
import { MssgContainer, getDate, getEstateID } from "../../components/partials/utils";
import WithAuth from "../../components/partials/withAuth";
import styled from "styled-components";

const Log = () => {
  const [data, setData] = useState({})
  const [logs, setLogs] = useState([])
  const [mssg, setMssg] = useState({ error: false, mssg: "" })
  const [search, setSearch] = useState("");
  const [activeIndex, setActiveIndex] = useState(1);
  const checkActive = (index) => (activeIndex === index ? "active" : "");

  const closeMssg = () => {
    setMssg({ error: false, mssg: "" })
  }

  useEffect(() => {
    const requestOptions = reqOptions('get', null, true)
    const api = `${HOST_URL()}estate_access_logs/?estate_id=${getEstateID()}`
    fetch(api, requestOptions)
      .then(res => res.json())
      .then(data => {
        setLogs(data.results)
        setData(data)
      })
      .catch(err => setMssg({ ...mssg, mssg: err.message, error: true }))
  }, [])

  const filterLogs = (index, name) => {
    setActiveIndex(index);
    document
      .querySelectorAll("tab")
      .forEach((item) =>
        item.classList.includes(activeIndex)
          ? item.classList.add("active")
          : item.classList.remove("active")
      );
    if (name != "ALL") {
      setLogs(data.results.filter((item) => item.status == name));
    } else {
      setLogs(data.results);
    }
  };

  const handleSearch = (e) => {
    const { value } = e.target
    setSearch(value)
    const requestOptions = reqOptions("get", null, true);
    const api = `${HOST_URL()}estate_access_logs/?estate_id=${getEstateID()}${value ? `&search=${value}` : ''}`;
    fetch(api, requestOptions)
      .then((res) => res.json())
      .then((data) => {
        setLogs(data.results)
        setData(data);
      })
      .catch((err) => setMssg({ ...mssg, mssg: err.message, error: true }));
  }
  return (
    <WithAuth>
      {mssg.mssg && <MssgContainer mssg={mssg} close={closeMssg} />}
      {/* <AccessLog /> */}
      <HandleSearchAndTab>
        <div className="tabs">
          <span
            className={`tab 1 ${checkActive(1)}`}
            onClick={() => filterLogs(1, "ALL")}
          >
            <button>All</button>
          </span>

          <span
            className={`tab 2 ${checkActive(2)}`}
            onClick={() => filterLogs(2, "ENTRY")}
          >
            <button>Entry</button>
          </span>
          <span
            className={`tab 3 ${checkActive(3)}`}
            onClick={() => filterLogs(3, "EXIT")}
          >
            <button>Exit</button>
          </span>
        </div>

        <div className="event-input">
          <div class="search_set">
            <img src="https://www.svgrepo.com/show/13682/search.svg" alt="" />
            <input
              className="eventt"
              type="text"
              name="name"
              placeholder="Search"
              onChange={handleSearch}
              value={search}
            />
          </div>
        </div>
      </HandleSearchAndTab>
      <div className="scrollable_table">
        <table>
          <thead>
            <tr>
              <th>Resident</th>
              <th>Visitor</th>
              <th>Address</th>
              <th>Entry Date & Time</th>
              <th>Exit Date & Time</th>
              <th>Type</th>
            </tr>
          </thead>
          <tbody>
            {console.log('logs[0]', logs[0])}
            {logs && logs.length ? logs.map((log, index) => (
              <tr key={index}>
                <td>
                  <div className="img-avatar">
                    <div className="user-name">
                      {log.estate_user.user.first_name} {log.estate_user.user.last_name}
                    </div>
                  </div>
                </td>
                <td>{log.last_name} {log.first_name}</td>
                <td>{log.address}</td>
                <td>
                  <div className="date-time">{log.from_date ? getDate(log.from_date) : 'Not applicable'}</div>
                </td>
                <td>
                  <div className="date-time">{log.to_date ? getDate(log.to_date) : 'Not applicable'}</div>
                </td>
                <td className="resume_data">
                  <p className={log.status === "ENTRY" ? "entry" : "exit"}>
                    {log.access_log_type}
                  </p>
                </td>
              </tr>
            )) : ""}
          </tbody>
        </table>
      </div>
    </WithAuth>
  );
};

export default Log;

const HandleSearchAndTab = styled.section`
  .container {
    margin-bottom: 20px;
  }
  .normal_tab {
    background: transparent;
  }
  .event-input {
    max-width: 675px;
  }
  @media (min-width: 760px) {
    display: flex;
    justify-content: space-between;
    .event-input {
      display: flex;
      justify-content: space-between;
      //   width: 57%;
      .select_me {
        margin-left: 10px;
        width: auto !important;
      }
      button {
        margin-top: 0;
        height: 45px;
      }
      .event-select {
        width: 20%;
      }
    }
  }
  @media (max-width: 540px) {
    .event-input {
      .event-select {
        display: none;
      }
    }
  }
  .tabs {
    margin: 0;
    margin-bottom: 30px;
    .tab {
      margin: 0;
      height: 45px;
      align-items: center;
      display: flex;
      justify-content: center;
      padding: 0;
      min-width: 100px;

      text-align: center;
      margin-right: 15px;
      border-bottom: 3px solid #c0c0c0;
      button {
        margin: 0;
        padding: 0 !important;
        padding: 10px;
        //styleName: Web/Small Copy;

        font-size: 16px;
        font-weight: 400;
        line-height: 17px;
        letter-spacing: 0em;
        text-align: left;
      }
    }
    .active {
      background: transparent;
      border-bottom: 3px solid #2d4bf3;
      button {
        color: #2d4bf3;
      }
    }
  }
  .normal_tab.add_tape {
    border-bottom: 2px solid yellow;
  }
`;